import React, { useEffect, useState } from 'react';

import Logo from '~components/auth/Logo';
import SignInForm from '~components/auth/SignIn/Form';
import IdentForm from '~components/auth/Ident/Form';
import StyledLogin from './styles';

import { AuthnMethodVer } from '../../types/common';

interface SignInArgs { handlerAuthnVerSet: (ver: AuthnMethodVer, ident: string) => void }

const SignIn = ({ handlerAuthnVerSet }: SignInArgs) => {
  const [hasAuthnMethodVer, setHasAuthnMethodVer] = useState(false);
  const [authnMethodVer, setAuthnMethodVer] = useState('v1');
  const [userIdent, setUserIdent] = useState('');

  const handlerGetAuthnMethodVer = (ver: AuthnMethodVer, ident: string) => {
    setHasAuthnMethodVer(true);
    setAuthnMethodVer(ver);
    setUserIdent(ident);
    if (ver === 'v2') {
      handlerAuthnVerSet(ver, ident);
    }
  }

  /**
   * Intentional reload to trigger new app version download
   * TODO: perhaps there is a better/more reliable way
   */
  useEffect(() => {
    if (localStorage.getItem('logout') == null) {
      localStorage.setItem('logout', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <StyledLogin
      data-testid="components:auth:signin:signin"
      className="main-login"
    >
      <div className="login-wrap">
        <div className="login-form">
          <Logo />
          {hasAuthnMethodVer && authnMethodVer === 'v1' ? (<SignInForm userIdent={userIdent} />) : (<IdentForm handleSubmit={handlerGetAuthnMethodVer} />)}
        </div>
      </div>
    </StyledLogin>
  );
};

export default SignIn;
