const langAuth = {
  identifier: {
    en: 'Username or email',
    es: 'Nombre de usuario o correo electrónico',
    pt: 'Nome de utilizador ou e-mail',
  },
  username: {
    en: 'Username',
    es: 'Usuario',
    pt: 'Usuario',
  },
  password: {
    en: 'Password',
    es: 'Contraseña',
    pt: 'Senha',
  },
  button_login: {
    en: 'Login',
    es: 'Iniciar sesión',
    pt: 'Conecte-se',
  },
  button_continue: {
    en: 'Continue',
    es: 'Continuar',
    pt: 'Continuar',
  },
  error_authentication: {
    en: 'Username or Password incorrect',
    es: 'Usuario o contraseña incorrecto',
    pt: 'Usuário ou senha incorretos',
  },
  error_authn_method_check: {
    en: 'Sorry, an error has occured. Please try again later.',
    es: 'Lo sentimos, se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
    pt: 'Desculpe, ocorreu um erro. Tente novamente mais tarde.',
  },
  warning_account_invite_not_accepted: {
    en: `Please accept an invitation sent to your email address, before trying again.`,
    es: 'Por favor, acepte una invitación enviada a su dirección de correo electrónico antes de volver a intentarlo.',
    pt: 'Por favor, aceite um convite enviado para o seu endereço de e-mail, antes de tentar novamente.',
  }
};

export default langAuth;
