import repository from './repository';
import lang from '../lang/langAuth';
import { browserPreferredLang } from '../../../utils/language';

import { AuthLanguage, AuthTranslationItem, FormState, ManageCtrlStateArgs } from '../types';

const transAuth: { [key: string]: AuthTranslationItem } = lang;

interface Translations {
  label: {
    identifier: string;
    authnMethodCheckError: string;
    inviteAcceptRequired: string;
  };
  btn: {
    continue: string;
  };
}

interface PresenterArgs {
  ID_CTRL_USERNAME: string;
}

interface PresenterReturn {
  manageCtrlState: typeof manageCtrlState;
  initialFormState: { [key: string]: FormState };
  handleSubmit: ({
    username,
  }: {
    username: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>;
  trans: Translations;
}

const manageCtrlState = ({ state, name, value }: ManageCtrlStateArgs) => {
  const def = { ...state[name] };

  return {
    ...state,
    [name]: {
      ...def,
      isTouched: true,
      value,
    },
  };
};

function presenter({ ID_CTRL_USERNAME }: PresenterArgs): PresenterReturn {
  const repo = repository();
  const initialFormState = {
    [ID_CTRL_USERNAME]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      className: 'input--login',
      value: window.ENV === 'development' ? 'trader1' : '',
      validators: [
        {
          rule: 'required',
          feedback: 'Email or username: required',
        },
      ],
    },
  };

  const langDefault = window.APP_NAME !== 'latamfx' ? 'en' : 'es';
  const langFromBrowser = <AuthLanguage>browserPreferredLang();

  const identifierTrans =
    transAuth.identifier[langFromBrowser] || transAuth.identifier[langDefault];
  const authnMethodCheckErrorTrans =
    transAuth.error_authn_method_check[langFromBrowser] ||
    transAuth.error_authn_method_check[langDefault];
  const continueTrans =
    transAuth.button_continue[langFromBrowser] ||
    transAuth.button_continue[langDefault];
  const inviteAcceptRequiredTrans = 
    transAuth.warning_account_invite_not_accepted[langFromBrowser] ||
    transAuth.warning_account_invite_not_accepted[langDefault];

  const trans = {
    label: {
      identifier: identifierTrans,
      authnMethodCheckError: authnMethodCheckErrorTrans,
      inviteAcceptRequired: inviteAcceptRequiredTrans
    },
    btn: {
      continue: continueTrans,
    },
  };

  const handleSubmit = ({ username }: { username: string }) =>
    repo.apiAuthnMethodVerRequest({ username });

  return Object.freeze({
    manageCtrlState,
    initialFormState,
    handleSubmit,
    trans,
  });
}

export default presenter;
