import React, { useState, useRef, FormEvent } from 'react';

import presenter  from './presenter';
import { CtrlState } from '../types';

const ID_CTRL_USERNAME = 'username';

const Form = ({handleSubmit}: {handleSubmit: (ver: string, ident: string) => void}) => {
  const presenting = presenter({
    ID_CTRL_USERNAME,
  });
  const initialFormState = useRef<CtrlState>(presenting.initialFormState);
  const [ctrlState, setCtrlState] = useState<CtrlState>(
    initialFormState.current
  );

  const [error, setError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    setIsAuthenticating(true);

    presenting
      .handleSubmit({
        username: ctrlState[ID_CTRL_USERNAME].value,
      })
      .then(({
        authnVer,
        isInviteAccepted,
      }) => {
        setIsAuthenticating(false);
        if(authnVer === 'v2') {
          if(isInviteAccepted) {
            handleSubmit(authnVer, ctrlState[ID_CTRL_USERNAME].value);
          } else {
            setError(presenting.trans.label.inviteAcceptRequired);
          }
        } else {
          handleSubmit(authnVer, ctrlState[ID_CTRL_USERNAME].value);
        }
      })
      .catch(() => {
        setIsAuthenticating(false);
        setError(presenting.trans.label.authnMethodCheckError);
      });
  };

  const handleInputUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCtrlState(
      presenting.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  return (
    <form data-testid="components:auth:signin:form-ident" onSubmit={submitHandler}>
      <div className="login-data-form" id="mextraderLogin">
        {error && (
          <span role="alert" className="error">
            {error}
          </span>
        )}
        <label htmlFor={ID_CTRL_USERNAME}>
          {presenting.trans.label.identifier}
        </label>
        <input
          data-testid={ID_CTRL_USERNAME}
          id={ID_CTRL_USERNAME}
          value={ctrlState[ID_CTRL_USERNAME].value}
          required={ctrlState[ID_CTRL_USERNAME].isRequired}
          type="text"
          className={ctrlState[ID_CTRL_USERNAME].className}
          autoComplete="username"
          onChange={handleInputUpdate}
        />
        {isAuthenticating ? (
          <div className="spinner-container">
            <div className="spinner-login" />
          </div>
        ) : (
          <button data-testid="submit-button" type="submit">
            {presenting.trans.btn.continue}
          </button>
        )}
      </div>
    </form>
  );
};

export default Form;
