import { createSelector } from 'reselect';

const orderSettingsSelector = createSelector(
  [
    (state) => state?.widgets_configuration?.orders_settings,
    (state) => state?.widgets_configuration?.order_types,
  ],
  (orders_settings, orderTypesAllowed) => ({
    orderTypesAllowed,
    manoffsetWarning:
      orders_settings?.manoffset_warning === undefined
        ? true
        : orders_settings?.manoffset_warning,
    allowIfDone: orders_settings?.allow_if_done || false,
    allowNote: orders_settings?.allow_client_order_text_note || false,
    marketClosingTime: Number(orders_settings?.market_closing_time || 25),
    marketClosedTime: Number(orders_settings?.market_closed_time || 20),
    metalsFixingMarketOverride:
      orders_settings?.metals_fixing_market_override || false,
    enableTestOrder: orders_settings?.enable_test_order || false,
    skipRateEngineClientOrders:
      orders_settings?.skip_rate_engine_client_orders || false,
    ordersCache: orders_settings?.orders_cache || false,
  })
);

export default orderSettingsSelector;
