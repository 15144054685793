import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';

import presenter from './presenter';
import { CtrlState } from '../types';

const ID_CTRL_USERNAME = 'username';
const ID_CTRL_PASSWORD = 'current-password';

const Form = ({ userIdent }: { userIdent: string }) => {
  const presenting = presenter({
    ID_CTRL_USERNAME,
    ID_CTRL_PASSWORD,
  });
  const { manageCtrlState } = presenting;
  const initialFormState = useRef<CtrlState>(presenting.initialFormState);
  const inputPassword = useRef<HTMLInputElement>(null);
  const [ctrlState, setCtrlState] = useState<CtrlState>(
    initialFormState.current
  );
  const [error, setError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const history = useHistory();

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    setIsAuthenticating(true);

    presenting
      .handleSubmit({
        username: ctrlState[ID_CTRL_USERNAME].value,
        password: ctrlState[ID_CTRL_PASSWORD].value,
      })
      .then(() => {
        setIsAuthenticating(false);
        history.push({ pathname: '/dashboard' });
      })
      .catch(() => {
        setIsAuthenticating(false);
        setError(presenting.trans.label.authnError);
      });
  };

  const handleInputUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCtrlState(
      presenting.manageCtrlState({ state: ctrlState, name: id, value })
    );
  };

  useEffect(() => {
    setCtrlState((prev) => manageCtrlState({ state: prev, name: ID_CTRL_USERNAME, value: userIdent }))
    if(userIdent) inputPassword.current?.focus();
  }, [manageCtrlState, userIdent])

  return (
    <form data-testid="components:auth:signin:form" onSubmit={submitHandler}>
      <div className="login-data-form" id="mextraderLogin">
        {error && (
          <span role="alert" className="error">
            {error}
          </span>
        )}
        <label htmlFor={ID_CTRL_USERNAME}>
          {presenting.trans.label.username}
        </label>
        <input
          data-testid={ID_CTRL_USERNAME}
          id={ID_CTRL_USERNAME}
          value={ctrlState[ID_CTRL_USERNAME].value}
          required={ctrlState[ID_CTRL_USERNAME].isRequired}
          type="text"
          className={ctrlState[ID_CTRL_USERNAME].className}
          autoComplete="username"
          onChange={handleInputUpdate}
        />
        <label htmlFor={ID_CTRL_PASSWORD}>
          {presenting.trans.label.password}
        </label>
        <input
          data-testid={ID_CTRL_PASSWORD}
          id={ID_CTRL_PASSWORD}
          ref={inputPassword}
          value={ctrlState[ID_CTRL_PASSWORD].value}
          required={ctrlState[ID_CTRL_PASSWORD].isRequired}
          type="password"
          className={ctrlState[ID_CTRL_PASSWORD].className}
          autoComplete="current-password"
          onChange={handleInputUpdate}
        />

        {isAuthenticating ? (
          <div className="spinner-container">
            <div className="spinner-login" />
          </div>
        ) : (
          <button data-testid="submit-button" type="submit">
            {presenting.trans.btn.signIn}
          </button>
        )}
      </div>
    </form>
  );
};

export default Form;
