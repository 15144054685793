const decimalsForPair = ({
  id,
  symbol,
}: {
  id: string | number;
  symbol: string;
}): number => {
  let hackPrecision = 5;
  if (Number(id) === 58) hackPrecision = 3;
  if (Number(id) === 7601) hackPrecision = 4;
  if (Number(id) === 7801) hackPrecision = 2;

  if (symbol && hackPrecision === 5) {
    if (
      [
        'EUR/CZK',
        'EUR/MXN',
        'USD/CZK',
        'USD/MXN',
        'USD/PEN',
        'USD/INR',
      ].includes(symbol)
    ) {
      hackPrecision = 4;
    } else if (['USD/JPY', 'USD/PHP', 'USD/TWD'].includes(symbol)) {
      hackPrecision = 3;
    } else if (['USD/CLP', 'USD/COP', 'USD/KRW', 'USD/IDR'].includes(symbol)) {
      hackPrecision = 2;
    }
  }
  return hackPrecision;
};

export default decimalsForPair;
