import { createSelector } from 'reselect';
import { Instrument } from '~types/common';
import RootState from '~types/rootState';

const getInstruments = (state: RootState) => state.assets;

const getInstrumentId = (_: RootState, id: number | string) => id;

// https://reselect.js.org/usage/best-practices/
// https://reselect.js.org/usage/handling-empty-array-results

export const allInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets) => (assets.length ? assets : [])
);

export const nonMetalFixingInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (instrument: Instrument) => instrument.type !== 'metal_fixing'
    )
);

export const metalFixingInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (instrument: Instrument) => instrument.type === 'metal_fixing'
    )
);

export const clPrInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (inst: Instrument) =>
        ['fx', 'ndf'].includes(inst.type) && inst.tenor === 'rfq'
    )
);

export const tradingInstrumentsSelector = createSelector(
  [(state) => state.assets],
  (assets = []) =>
    assets.filter(
      (instrument: Instrument) =>
        instrument.tenor !== 'rfq' &&
        instrument.tenor !== 'swap' &&
        instrument.type !== 'metal_fixing'
    )
);

export const getTradingInstrumentById = createSelector(
  [(assets) => assets, getInstrumentId],
  (assets: Instrument[], instrumentId): Instrument =>
    Object.values(assets).find(
      ({ instrument_id: id }) => Number(id) === Number(instrumentId)
    )
);

const instrumentsTypeSelector = (_: RootState, tenor: string) =>
  tenor === 'sp' ? 'rfq' : tenor;

export const rfqInstrumentsSelector = createSelector(
  [getInstruments, instrumentsTypeSelector],
  (instruments, tenor) => {
    const parsedPairs: { [key: string]: string[] } = {};

    instruments
      .sort((a, b) => a.symbol.localeCompare(b.symbol))
      .forEach((i) => {
        if (!parsedPairs[i.symbol]) {
          parsedPairs[i.symbol] = [i.type, i.tenor];
        } else {
          parsedPairs[i.symbol] = parsedPairs[i.symbol].concat([
            i.type,
            i.tenor,
          ]);
        }
      });

    return instruments.filter(({ symbol, tenor: iTenor, type }) => {
      switch (tenor) {
        case 'ndf':
          return (
            parsedPairs[symbol].includes('rfq') &&
            parsedPairs[symbol].includes('ndf') &&
            iTenor === 'rfq' &&
            type === 'ndf'
          );
        case 'nds':
          return (
            parsedPairs[symbol].includes('swap') &&
            parsedPairs[symbol].includes('ndf') &&
            iTenor === 'swap' &&
            type === 'ndf'
          );
        case 'swap':
          return (
            parsedPairs[symbol].includes('swap') &&
            !parsedPairs[symbol].includes('ndf') &&
            iTenor === 'swap' &&
            type === 'fx'
          );
        default:
          return (
            parsedPairs[symbol].includes(tenor) &&
            !parsedPairs[symbol].includes('ndf') &&
            iTenor === 'rfq' &&
            type === 'fx'
          );
      }
    });
  }
);

export const maxAmountOrderByInstrumentSelector = createSelector(
  [getInstruments, getInstrumentId],
  (instruments, id) => {
    if (id) {
      const { max_amount_per_order: maxAmount } = Object.values(
        instruments
      ).find(
        ({ instrument_id: instrumentId }) => Number(id) === Number(instrumentId)
      );

      return maxAmount;
    }
    return '';
  }
);
