import React, { lazy, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Loading from '~components/common/Loading';
import {
  SET_AUTHENTICATION_TOKEN,
  USER_DETAILS,
  USER_PERMISSIONS,
} from '~actions/action_types';
import { AuthnMethodVer } from '~types/common';
import RequireClassicAuth from './components/auth/RequireClassicAuth';
import { Dashboard, NotFound } from './pages';
import SignIn from './pages/Login';
import getUserConfig from './callers/getUserConfig';
import Auth0ProviderWrapper from './Auth0ProviderWrapper';
import useInterval from './hooks/useInterval';

const ONE_MINUTE = 60000;

const Desktop = lazy(() => import('./pages/desktop'));

const HOC = () => (
  <RequireClassicAuth>
    <Dashboard />
  </RequireClassicAuth>
);

const InviteRoute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const invitation = urlParams.get('invitation');
  const organization = urlParams.get('organization');
  sessionStorage.setItem('authn_method_ver', 'v2');

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // this piece of logic is meant to allow
    // creating and accepting invites for auth0
    // either in a platform that already supports it or not
    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation,
        },
      });
    }
  }, [loginWithRedirect, invitation, organization]);

  return <Loading />;
};

const ClassicRoutes = ({ handlerAuthnVerSet }: { handlerAuthnVerSet: (ver: "v1" | "v2", ident: string) => void }) => (
  <Router>
    <Switch>
      <Route path="/" exact component={() => <SignIn handlerAuthnVerSet={handlerAuthnVerSet} />} />
      <Route path="/desktop" component={Desktop} />
      <Route path="/dashboard" component={HOC} />
      <Route path="/widget" component={HOC} />
      <Route path="/widget-popout" component={() => <div />} />
      <Route path="/invite">
        <Auth0ProviderWrapper>
          <InviteRoute />
        </Auth0ProviderWrapper>
      </Route>
      <Route component={NotFound} />
    </Switch>
  </Router>
);

const Auth0Routes = ({ userIdent }: { userIdent: string }) => {
  const dispatch = useDispatch();
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();

  const urlParams = new URLSearchParams(window.location.search);
  const invitation = urlParams.get('invitation');
  const organization = urlParams.get('organization');

  const [isReady, setIsReady] = useState(false);
  const [tokenExpireTime, setTokenExpireTime] = useState(ONE_MINUTE);

  const fetchAccessToken = useCallback(async () => {
    const { access_token: accessToken, expires_in } = await getAccessTokenSilently({
      detailedResponse: true,
    });

    // set token expire time to one minute before
    // it's meant to expire
    const expiresMiliseconds = expires_in * 1000;
    setTokenExpireTime(expiresMiliseconds - ONE_MINUTE);

    sessionStorage.setItem('token', `Bearer ${accessToken}`);
    sessionStorage.setItem('AuthIToken', accessToken);
    dispatch({ type: SET_AUTHENTICATION_TOKEN, payload: accessToken });
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    const getUsrCfg = async () => {
      const accessToken = await getAccessTokenSilently();
      const response = await getUserConfig(accessToken);

      const {
        username,
        id_companies: idCompany,
        company_name: companyName,
        id_users: userId,
        rate_engine_enabled: rateEngineEnabled,
        shadow_admin: shadowAdmin,
        is_slave: isViewer,
        is_enable_websocket_routing: isEnableWebsocketRouting = false,
        permissions,
      } = response.user;

      sessionStorage.setItem('global_username', username);
      sessionStorage.setItem('global_org_id', idCompany);
      sessionStorage.setItem('global_organization', companyName);

      dispatch({
        type: USER_PERMISSIONS,
        payload: permissions,
      });
      dispatch({
        type: USER_DETAILS,
        payload: {
          username,
          userId,
          organization: companyName,
          organizationId: idCompany,
          rateEngineEnabled,
          shadowAdmin,
          isViewer,
          isEnableWebsocketRouting,
        },
      });
      setIsReady(true);
    }

    if (isAuthenticated) {
      getUsrCfg();
    }

  }, [dispatch, getAccessTokenSilently, isAuthenticated]);

  useInterval(() => {
    if (isAuthenticated) {
      fetchAccessToken();
    }
  }, tokenExpireTime)

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !invitation && !organization) {
      loginWithRedirect({ authorizationParams: { login_hint: userIdent } });
    }
  }, [isLoading, isAuthenticated, invitation, organization, loginWithRedirect, userIdent]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchAccessToken()
    }
  }, [fetchAccessToken, isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          {isReady ? (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: '/' },
              }}
            />
          ) : null}
        </Route>
        <Route path="/desktop" component={Desktop} />
        <Route path="/dashboard" exact>
          {!isReady ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: '/dashboard' },
              }}
            />
          ) : (
            <Dashboard handleAuth0WsFail={fetchAccessToken} />
          )}
        </Route>
        <Route path="/widget" component={Dashboard} />
        <Route path="/widget-popout" component={() => <div />} />
        <Route path="/invite" component={InviteRoute} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

const Routes = ({ isAuth0 }: { isAuth0: boolean }) => {
  const [authnVer, setAuthnVer] = useState('v1');
  const [userIdent, setUserIdent] = useState('');
  const handleAuthnVer = (ver: AuthnMethodVer, ident: string) => {
    setAuthnVer(ver);
    setUserIdent(ident);
  }
  return (isAuth0 || authnVer === 'v2' ? <Auth0Routes userIdent={userIdent} /> : <ClassicRoutes handlerAuthnVerSet={handleAuthnVer} />)
}

export default Routes;
