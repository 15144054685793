const instruments = {
  enabled: true,
  add_instrumets: true,
  delete_instrument: true,
};

const orders = {
  enable: true,
  modify_order: true,
  cancel_order: true,
  show_all: true,
  show_active: true,
  show_finished: true,
  watch_organization: true,
  cancel_all: true,
};

const blotter = {
  enabled: true,
  show_orders_today: true,
  show_trades_today: true,
  show_trades_yesterday: true,
  show_trades_week: true,
  pl_today: true,
  watch_organization: true,
  modify_order: true,
  cancel_order: true,
  sort: true,
  filter: true,
};

const restingorders = {
  enabled: true,
};

const trading = {
  enabled: true,
  create_order: true,
  show_positions: true,
  show_as_stream: true,
  show_as_book: true,
};

const layout = {
  instruments: true,
  vwap: true,
  chart: true,
  orders: true,
  blotter: true,
  trading: true,
  layouts: {
    default: true,
    minimal: true,
    viewer: true,
  },
};

const multicurrencies = {
  enabled: false,
};

const rfs = {
  enabled: false,
};

const pnl = {
  enabled: false,
};

const rfq = {
  enabled: false,
};

const rfqBlotter = {
  enabled: false,
};

const chart = { enabled: false };

const economicCalendar = {
  enabled: false,
};

const rssFeed = {
  enabled: false,
};

const heatmapWidget = {
  enabled: false,
};

module.exports = {
  instruments_widget: instruments,
  orders_widget: orders,
  blotter_widget: blotter,
  trading_widget: trading,
  multicurrency_widget: multicurrencies,
  rfs_widget: rfs,
  rfq_widget: rfq,
  rfq_blotter_widget: rfqBlotter,
  restingorders_widget: restingorders,
  layout,
  pnl_widget: pnl,
  chart_widget: chart,
  economic_calendar: economicCalendar,
  rss_feed: rssFeed,
  heatmap_widget: heatmapWidget,
};
