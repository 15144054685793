import { httpOrigin } from '../../../configs/apiOrigin';
import logger from '../../../utils/logger';

function repository() {
  const origin = httpOrigin('/trader-rest-service/authn-method-check');
  let httpCode = 200;

  const apiAuthnMethodVerRequest = async ({ username = '' } = {}) => {
    try {
      const apiResponse = await fetch(`${origin}/${username}`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain',
        },
      });

      httpCode = apiResponse.status;
      if (!apiResponse.ok && httpCode !== 200 && httpCode !== 409) {
        logger.logWarning('Authentication method check failed');
        return 'v1';
      }
      const resTxt = await apiResponse.text();

      const response = {
        authnVer: resTxt,
        isInviteAccepted: false,
      };
      if (resTxt === 'v2' && httpCode === 409) {
        return response;
      }
      sessionStorage.setItem('authn_method_ver', resTxt);
      if (resTxt === 'v2') response.isInviteAccepted = true;
      return response;
    } catch (err) {
      logger.logCriticalError(err);
      return 'v1';
    }
  };

  return Object.freeze({
    apiAuthnMethodVerRequest,
  });
}

export default repository;
