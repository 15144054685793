import { orders as l } from '~configs/language';
import { Language, Order } from '~types/common';
import decimalsForPair from './decimalsForPair';

const { order_modal: orderModal } = l;

// instrument ids for IPS instruments
export const MAR = 17901;
export const TOMMAR = 17902;
export const BASIS_MAR = 17978;
export const BASIS_TOMMAR = 17979;

export const marInstruments = [MAR, TOMMAR, BASIS_MAR, BASIS_TOMMAR];
export const marInstrumentsWithNdfInName = [MAR, TOMMAR];

// https://visionfx.atlassian.net/browse/IPS-259
// for IPS we need to respect the order of these instruments
// for the trading widgets dropdown
export const ipsInstrumentsOrder = [
  7899,
  7801,
  MAR,
  TOMMAR,
  BASIS_MAR,
  BASIS_TOMMAR,
];

export const isMARInstrument = (instrumentId: string | number) =>
  marInstruments.includes(Number(instrumentId));

// IPS-150, IPS-149 and IPS-158 ( Exchange Rate to Forward Points )
export const ipsRestOrderPriceCustomLabel = (
  orderType: string,
  name: string,
  lang: Language,
  instrumentId: string | number,
  labelText: string
): string => {
  if (
    name === 'price' &&
    ['LIMIT', 'MAN_OFFSET', 'SLICER', 'ICEBERG'].includes(orderType) &&
    isMARInstrument(instrumentId)
  ) {
    return orderModal.price.label_price_mar[lang];
  }

  return labelText;
};

// IPS-132, IPS-158 (disable the Price Range validation)
export const skipPriceRangeValidate = (instrumentId: string | number) =>
  isMARInstrument(instrumentId);

// IPS-147, IPS-158
// Must show the + sign on positive numbers for MAR Instruments
export const askBidPositiveSign = (
  instrumentId: string | number,
  price: string
) => (isMARInstrument(instrumentId) && price && Number(price) > 0 ? `+` : '');

export const priceAlignStyle = (
  instrumentId: string | number,
  price: string
) => ({
  alignSelf: price === '---' ? 'center' : 'baseline',
  ...(isMARInstrument(instrumentId) && price && price !== '---'
    ? { fontSize: '2.8em' }
    : {}),
});

export const incrementStepDecimals = ({
  id,
  symbol,
}: {
  id: string | number;
  symbol: string;
}): number => {
  if (isMARInstrument(id)) {
    return 0;
  }

  if (symbol === 'USD/KRW') {
    // 7801 (usd-krw.ndf.1m), 7899 (usd-krw.fx.1m)
    return 1;
  }

  return decimalsForPair({ id, symbol });
};

export const getIpsTenorName = (
  instrumentId: number,
  tenor: string,
  type: string
) => {
  if (marInstrumentsWithNdfInName.includes(instrumentId)) {
    return `NDF ${tenor}`.toUpperCase();
  }

  if (marInstruments.includes(instrumentId)) {
    return tenor.toUpperCase();
  }

  if (type === 'ndf') {
    return `NDF ${tenor}`.toUpperCase();
  }

  return `DF ${tenor}`.toUpperCase();
};

export const getIpsTenorNameFromSwap = (order: Order) => {
  switch (order.instrument_id) {
    case MAR: {
      if (order.isNearLeg) {
        return 'NDF SP';
      }

      return 'NDF 1M';
    }
    case TOMMAR: {
      if (order.isNearLeg) {
        return 'NDF TOM';
      }

      return 'NDF TOM1M';
    }
    case BASIS_TOMMAR: {
      if (order.isNearLeg) {
        return 'NDF TOM';
      }

      return 'DF TOM';
    }
    case BASIS_MAR: {
      if (order.isNearLeg) {
        return 'NDF SP';
      }

      return 'DF SP';
    }
    default:
      return '';
  }
};
